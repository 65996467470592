const BASE_URL = 'https://housechartservice.obzer.io';
// const BASE_URL = 'http://172.21.10.21:4001';

// 封装GET请求
const get = () => {
    return fetch(`${BASE_URL}/load`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // 如有需要，可以添加其他头部信息
        },
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error("GET request failed", error);
            throw error;
        });
}

// 封装POST请求
const post = (data) => {
    return fetch(`${BASE_URL}/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 如有需要，可以添加其他头部信息
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error("POST request failed", error);
            throw error;
        });
}

const getRows = (props) => {
    let _rows = [];
    props.data.house.forEach(function (item) {
        let rating1 = props.data.rating[0];
        let rating2 = props.data.rating[1];
        let eileen = 0;
        let yiguang = 0;
        rating1.forEach(function (rating, index) {
            let _rating = parseInt(rating);
            eileen += (_rating / 100).toFixed(2) * parseInt(item[index + 1]);
        });
        rating2.forEach(function (rating, index) {
            let _rating = parseInt(rating);
            yiguang += (_rating / 100).toFixed(2) * parseInt(item[index + 1]);
        });
        _rows.push({
            data: item,
            name: item[0],
            eileen: rating1,
            eileenRating: eileen,
            yiguang: rating2,
            yiguangRating: yiguang,
            total: (eileen + yiguang).toFixed(2),
        });
    });
    return _rows;
}

export { get, post, getRows };