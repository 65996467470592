import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import RadarChart from './RadarChart';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function SimpleDialog(props) {
  const { ratingModel, house, open, onClose } = props;
  let key = [
    '价格',
    '医疗',
    '教育',
    '房屋',
    '交通',
    '升值',
    '房龄',
    '商业',
    '社区',
    '政策',
    '容积率',
  ];
  const columns = [
    { field: 'name', headerName: 'NAME', width: 130 },
    {
      field: 'eileenRating',
      headerName: `EILEEN'S RATING`,
      type: 'number',
      width: 130,
    },
    {
      field: 'yiguangRating',
      headerName: `YIGUANG'S RATING`,
      type: 'number',
      width: 150,
    },
    {
      field: 'rating',
      headerName: `RATING`,
      type: 'number',
      width: 150,
    }
  ];
  const [mode, setMode] = React.useState({ text: "Table", flag: true });
  const handleModeChange = function () {
    if (mode.flag) setMode({ text: "Radar", flag: false })
    else setMode({ text: "Table", flag: true })
  }
  let rows = [];
  let total = { e: 0, y: 0, t: 0 }

  house.data.forEach(function (item, index) {
    if (index) {
      let e = parseInt((item * house.eileen[index - 1])) / 100;
      let y = parseInt((item * house.yiguang[index - 1])) / 100;
      rows.push({
        name: key[index - 1],
        eileenRating: e,
        yiguangRating: y,
        rating: item
      })
      total.e += e;
      total.y += y;
      total.t += item;
    }
  })
  rows.push({ name: "total", eileenRating: total.e, yiguangRating: total.y, rating: total.t })
  const getRowId = (row) => {
    return new Date().getTime() + Math.random();
  };
  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <DialogTitle><Typography align="center">{house.data[0]}</Typography></DialogTitle>
      <Typography align="center"><ButtonGroup
      >
        <Button onClick={handleModeChange}>{mode.text}</Button>
      </ButtonGroup></Typography>

      <br />
      <Box>
        {mode.flag && <RadarChart
          labels={key}
          rating={[house.eileen, house.yiguang]}
          name={house.data[0]}
          data={house.data}
        />}
        {!mode.flag && <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[5, 10]}
          hideFooter={true}
          getRowId={getRowId}
        />}
      </Box>
    </Dialog >
  );
}

export default function DataTable(props) {
  return (
    <SimpleDialog
      ratingModel={props.ratingModel}
      house={props.house}
      open={props.open}
      onClose={props.onClose}
    />
  );
}
