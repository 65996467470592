import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MainTable from './MainTable';
import { get } from './DataService';


export default function App() {
  const [data, setData] = React.useState(false);
  React.useEffect(() => {
    get().then(data => { setData(data) })
  }, []);
  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Container maxWidth="md">
        <Box>
          <MainTable data={data} setData={setData} />
        </Box>
      </Container>
    </div>
  );
}
