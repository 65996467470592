import * as React from 'react';
import Button from '@mui/material/Button';
import { post, getRows } from './DataService';


export default function DataTable(props) {
    const { data, setData, setRows } =
        props;
    const handleClick = () => {
        let selectedValue = props.rating.data;
        data.house.forEach(function (item, index) {
            if (item[0] == selectedValue[0]) data.house.splice(index, 1);
        })
        post(data);
        setData(data);
        setRows(getRows(props))
    };


    return (
        <div>
            <Button size="small" onClick={handleClick}>
                {props.name}
            </Button>
        </div>
    );
}
