import * as React from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { post, getRows } from './DataService';

function SimpleDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    setSelectedValue,
    setData,
    ratingIndex,
    setRows,
    data,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleSaveClick = () => {
    setSelectedValue(selectedValue);
    data.rating[ratingIndex] = selectedValue;
    setData(data);
    post(data);
    setRows(getRows(props))
    onClose(selectedValue);
  };

  const setValue = (index, value) => {
    selectedValue[index] = value;
    setSelectedValue(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>权重(百分比,0-1000)</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">价格</InputLabel>
            <Input
              defaultValue={selectedValue[0]}
              onChange={(event) => {
                setValue(0, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">医疗</InputLabel>
            <Input
              defaultValue={selectedValue[1]}
              onChange={(event) => {
                setValue(1, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">教育</InputLabel>
            <Input
              defaultValue={selectedValue[2]}
              onChange={(event) => {
                setValue(2, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">房屋</InputLabel>
            <Input
              defaultValue={selectedValue[3]}
              onChange={(event) => {
                setValue(3, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">交通便利性</InputLabel>
            <Input
              defaultValue={selectedValue[4]}
              onChange={(event) => {
                setValue(4, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">潜在升值空间</InputLabel>
            <Input
              defaultValue={selectedValue[5]}
              onChange={(event) => {
                setValue(5, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">房龄和状况</InputLabel>
            <Input
              defaultValue={selectedValue[6]}
              onChange={(event) => {
                setValue(6, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">商业配套</InputLabel>
            <Input
              defaultValue={selectedValue[7]}
              onChange={(event) => {
                setValue(7, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">社区环境</InputLabel>
            <Input
              defaultValue={selectedValue[8]}
              onChange={(event) => {
                setValue(8, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">政策</InputLabel>
            <Input
              defaultValue={selectedValue[9]}
              onChange={(event) => {
                setValue(9, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard">
            <InputLabel htmlFor="component-simple">容积率</InputLabel>
            <Input
              defaultValue={selectedValue[10]}
              onChange={(event) => {
                setValue(10, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
        <ListItem>
          <Button size="small" onClick={handleSaveClick}>
            保存
          </Button>
          <Button size="small" onClick={handleClose}>
            取消
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function DataTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [selectedValue, setSelectedValue] = React.useState(
    props.data.rating[props.ratingIndex]
  );
  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <div>
      <Button size="small" onClick={handleClickOpen}>
        {props.name}
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setSelectedValue={setSelectedValue}
        ratingIndex={props.ratingIndex}
        setData={props.setData}
        data={props.data}
        setRows={props.setRows}
      />
    </div>
  );
}
