import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Details from './Details';
import Stack from '@mui/material/Stack';
import Rating from './Rating';
import New from './New';
import Edit from './Edit';
import Delete from './Delete';
import { getRows } from './DataService';

const columns = [
  {
    field: 'name',
    headerName: 'NAME',
    width: 130
  },
  {
    field: 'eileenRating',
    headerName: `EILEEN'S RATING`,
    type: 'number',
    width: 130,
  },
  {
    field: 'yiguangRating',
    headerName: `YIGUANG'S RATING`,
    type: 'number',
    width: 150,
  },
  {
    field: 'total',
    headerName: 'TOTAL',
    type: 'number',
    width: 140,
  }
];

export default function DataTable(prop) {
  const [open, setOpen] = React.useState(false);

  const [houseName, setHouseName] = React.useState('House');


  const handleRowClick = (params) => {
    setSelectedValue(params.row);
    setHouseName(params.row.name);
  };
  const handleRowDoubleClick = (params) => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };
  const [rows, setRows] = React.useState(getRows(prop));
  const [selectedValue, setSelectedValue] = React.useState(rows[0]);

  const getRowId = (row) => {
    return new Date().getTime() + Math.random();
  };
  return (
    <div style={{ width: '100%' }}>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <Rating
          name="Eileen's rating"
          data={prop.data}
          ratingIndex={0}
          setData={prop.setData}
          setRows={setRows}
        />
        <Rating
          name="Yiguang's rating"
          data={prop.data}
          ratingIndex={1}
          setData={prop.setData}
          setRows={setRows}
        />
        <New
          name="New House"
          rating={['NEW', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
          setData={prop.setData}
          data={prop.data}
          setRows={setRows}
        />
        <Edit
          name={`Edit ${houseName}`}
          rating={selectedValue}
          setData={prop.setData}
          setSelectedValue={setSelectedValue}
          data={prop.data}
          setRows={setRows}
        />
        <Delete
          name={`Delete ${houseName}`}
          rating={selectedValue}
          setData={prop.setData}
          setSelectedValue={setSelectedValue}
          data={prop.data}
          setRows={setRows}
        />
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
        }}
        pageSizeOptions={[5, 10]}
        hideFooter={true}
        onRowDoubleClick={handleRowDoubleClick}
        onRowClick={handleRowClick}
        {...rows}
        getRowId={getRowId}
      />
      <Details house={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
