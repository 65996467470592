import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function RadarChart(props) {
  let dataset = [];
  let dataset2 = [];
  props.labels.forEach(function (item, index) {
    dataset.push(props.rating[0][index] * props.data[index + 1]);
    dataset2.push(props.rating[1][index] * props.data[index + 1]);
  });
  let data = {
    clip: { left: 0, top: 0, right: 0, bottom: 0 },
    labels: props.labels,
    datasets: [
      {
        label: 'Eileen',
        data: dataset,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Yiguang',
        data: dataset2,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
      },
    ],
  }
  return <Radar data={data} />;
}
